import React from "react"
import { Link } from "gatsby"
import { FaBars } from "react-icons/fa"
import { AppContext } from "../context/context"
import MainNavigation from "../main-navigation/MainNavigation"
import styles from "./header.module.scss"
import { FaPhoneAlt } from "react-icons/fa"
const Header = ({
  logo,
  navItems,
  whereWeServiceActive,
  whereWeServiceSlug,
  isLandingPage = false,
  phone,
}) => {
  const { height, size, handleOpenNav } = React.useContext(AppContext)
  const [brand] = logo
  const shrink = height > 180 ? true : false
  const navCount = !isLandingPage && navItems.length

  if (!isLandingPage && size < 768) {
    return (
      <header className={styles.navBar}>
        <div className={`container ${styles.navBarInner}`}>
          <Link to="/">
            <img
              src={brand.fluid.src}
              alt={brand.description}
              className={styles.logo}
            />
          </Link>
          <FaBars className={styles.menu} onClick={handleOpenNav} />
        </div>
      </header>
    )
  } else {
    return (
      <header
        className={
          isLandingPage
            ? shrink
              ? `${styles.navBarLp} ${styles.navBarShrinkLp}`
              : styles.navBarLp
            : shrink
            ? `${styles.navBar} ${styles.navBarShrink}`
            : styles.navBar
        }
      >
        <div className={`container ${styles.navBarInner}`}>
          {isLandingPage ? 
            <img
            src={brand.fluid.src}
            alt={brand.description}
            className={styles.logo}
            />
            :
            <Link to="/">
            <img
              src={brand.fluid.src}
              alt={brand.description}
              className={styles.logo}
            />
          </Link>
        }
          {!isLandingPage && (
            <nav className={styles.mainNav}>
              {navItems.map(({ id, elements }, i) => {
                return (
                  <MainNavigation
                    key={id}
                    navItem={elements}
                    index={i}
                    navCount={navCount}
                    whereWeServiceActive={whereWeServiceActive}
                    whereWeServiceSlug={whereWeServiceSlug}
                  />
                )
              })}

              <FaBars className={styles.menu} onClick={handleOpenNav} />
            </nav>
          )}
          {isLandingPage &&
            (size > 490 ? (
              <span className={styles.phoneLink}>
                Call Today! <a href={`tel: ${phone.value}`}>{phone.value}</a>
              </span>
            ) : (
              <a className={styles.phoneIcon} href={`tel: ${phone.value}`}>
                <FaPhoneAlt />
              </a>
            ))}
        </div>
      </header>
    )
  }
}

Header.defaultProps = {
  logo: [],
  sticky: false,
  phone: "(207) 888-8888",
}

export default Header
