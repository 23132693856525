import React from "react"
import styles from "./footer.module.scss"
import { Link } from "gatsby"

const Credits = ({ footerNavItems, companyName }) => {
  let date = new Date().getFullYear()
  return (
    <section className={`container ${styles.credits}`}>
      Copyright © {date} {companyName} - All Rights Reserved.{" "}
      {footerNavItems.map(({ elements, id }, i) => {
        const external_link = elements?.external_link?.value ?? ""
        const text = elements?.link_text?.value ?? ""
        const slug = elements?.page?.value[0]?.elements?.slug?.value ?? ""

        if (external_link.length > 0) {
          return (
            <a
              key={id + i}
              className={styles.link}
              href={external_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {text}
            </a>
          )
        } else {
          return (
            <span key={id + i}>
              /{" "}
              <Link
                to={slug.startsWith("/") ? slug : `/${slug}`}
                className={styles.footerNav}
              >
                {text}
              </Link>{" "}
            </span>
          )
        }
      })}
      <section id="footer-additional-info-override"></section>
    </section>
  )
}

export default Credits
