import React from "react"
import { Link } from "gatsby"
import { BsEnvelope } from "react-icons/bs"
import styles from "./main-navigation.module.scss"

const MainNavigation = ({
  navItem,
  index,
  navCount,
  whereWeServiceSlug,
  whereWeServiceActive,
}) => {
  const { link_text, page, external_link } = navItem
  const slug = page?.value[0]?.elements?.slug?.value ?? ""
  const __typename = page?.value[0]?.__typename ?? ""
  let newSlug = slug.startsWith("/") ? slug : `/${slug}`
  if (
    __typename === "kontent_item_service_area" &&
    whereWeServiceActive === "true"
  ) {
    newSlug = `/${whereWeServiceSlug}/${slug}`
  }
  if (index + 1 === navCount && external_link.value === "") {
    return (
      <Link
        to={newSlug}
        className={styles.link}
        activeClassName={styles.current}
      >
        {link_text.value}
        {newSlug === "/contact-us" && <BsEnvelope />}
      </Link>
    )
  } else if (index + 1 === navCount && external_link.value !== "") {
    return (
      <Link
        to={external_link.value}
        className={styles.link}
        activeClassName={styles.current}
        target="_blank"
      >
        {link_text.value}
        {newSlug === "/contact-us" && <BsEnvelope />}
      </Link>
    )
  } else if (index + 1 !== navCount && external_link.value !== "") {
    return (
      <Link
        to={external_link}
        className={styles.link}
        activeClassName={styles.current}
        target="_blank"
      >
        {link_text.value}
      </Link>
    )
  } else {
    return (
      <Link
        to={newSlug}
        className={styles.link}
        activeClassName={styles.current}
      >
        {link_text.value}
      </Link>
    )
  }
}

export default MainNavigation
